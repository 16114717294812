import { ObservableBoolean } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { endOfDay, startOfDay } from 'date-fns'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { KeyType } from 'utils'

import { LDFlags$ } from 'trellis:state/globalState'

import { OnetimeEligibility } from '../../../../api/eligibility/eligibility-client'
import { EligibilityResponseProps } from '../EligibilityResponse'
import {
  ParticipatingCarrier,
  ProviderDetails,
} from '../utilities/eligibilityTyping'

type RequestStateType = {
  Data: any[]
  Filters: {
    Config?: { [key: KeyType]: any }
    CurrentPage: number
    Name?: string
    Options?: { [key: KeyType]: any }
    PageSize: number
    Search?: string
    SortColumn: string
    SortDirection: string
    Status?: string
  }
  Key: number
  Summary?: {
    // NOTE: only for appointments
    Appointments: number
    Eligible: number
    NeedsAttention: number
    PendingPatientResponse: number
  }
  Total: number
}

export type OneTimeRequestStateType = {
  Data: OnetimeEligibility[]
  Filters: {
    Config?: { [key: KeyType]: any }
    CurrentPage: number
    Name?: string
    PageSize: number
  }
  Key: number
  OriginalData: OnetimeEligibility[]
  Total: number
}

export type DateRangeState = {
  dates: [Date | null, Date | null]
  numberOfDays: number
}

type EligibilityContextType = {
  activeTab: string
  setActiveTab: Dispatch<SetStateAction<string>>
  appointmentDateRange: DateRangeState
  setAppointmentDateRange: Dispatch<SetStateAction<DateRangeState>>
  appointmentState: RequestStateType
  setAppointmentState: Dispatch<SetStateAction<RequestStateType>>
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  isActionCalledFromTable$: ObservableBoolean
  oneTimeRequestState: OneTimeRequestStateType
  setOneTimeRequestState: Dispatch<SetStateAction<OneTimeRequestStateType>>
  participatingCarriers: ParticipatingCarrier[]
  setParticipatingCarriers: Dispatch<SetStateAction<ParticipatingCarrier[]>>
  patientDateRange: DateRangeState
  setPatientDateRange: Dispatch<SetStateAction<DateRangeState>>
  patientState: RequestStateType
  setPatientState: Dispatch<SetStateAction<RequestStateType>>
  providers: ProviderDetails[]
  setProviders: Dispatch<SetStateAction<ProviderDetails[]>>
  requiresTableRefresh: boolean
  setRequiresTableRefresh: Dispatch<SetStateAction<boolean>>
  setShowEligibilityResponseModal: Dispatch<SetStateAction<boolean>>
  setShowPatientUpdateFormModal: Dispatch<SetStateAction<boolean>>
  setVerificationResponse: Dispatch<
    SetStateAction<EligibilityResponseProps | null>
  >
  showAppointmentsSummary$: ObservableBoolean
  showEligibilityResponseModal: boolean
  showPatientUpdateFormModal: boolean
  verificationResponse: EligibilityResponseProps | null
}

const EligibilityContext = createContext<EligibilityContextType>(undefined)

const EligibilityContextProvider: FC<{ children: JSX.Element }> = observer(
  ({ children }) => {
    const flags = LDFlags$.get()

    const getActiveTab = () => {
      let tab: string =
        flags?.eligibilityUsevynedentalsync ||
        flags?.operaVynesyncAutomatedeligibility
          ? 'appointments'
          : 'patients'

      let hash: string = window.location.hash
        ? window.location.hash.substring(1)
        : ''
      switch (hash) {
        case 'patients':
          if (
            flags?.eligibilityUsevynedentalsync ||
            flags?.operaVynesyncAutomatedeligibility
          )
            hash = 'appointments'
          break
        case 'appointments':
        case 'requests':
          if (
            !flags?.eligibilityUsevynedentalsync &&
            !flags?.operaVynesyncAutomatedeligibility
          )
            hash = 'patients'
      }

      if (
        hash &&
        hash !== 'patients' &&
        hash !== 'appointments' &&
        hash !== 'requests'
      ) {
        hash = ''
        window.location.hash = ''
      }

      if (hash) {
        window.location.hash = `#${hash}`
        tab = hash
      }

      return tab
    }

    const [activeTab, setActiveTab] = useState<string>(getActiveTab())
    const [loading, setLoading] = useState<boolean>(false)
    const [participatingCarriers, setParticipatingCarriers] = useState<
      ParticipatingCarrier[]
    >([])
    const [providers, setProviders] = useState<ProviderDetails[]>([])
    const [showEligibilityResponseModal, setShowEligibilityResponseModal] =
      useState<boolean>(false)
    const [showPatientUpdateFormModal, setShowPatientUpdateFormModal] =
      useState(false)
    const [verificationResponse, setVerificationResponse] =
      useState<EligibilityResponseProps>(null)
    const [requiresTableRefresh, setRequiresTableRefresh] =
      useState<boolean>(false)

    const showAppointmentsSummary$ = useObservable<boolean>(true)

    // state for patients
    const [patientState, setPatientState] = useState<RequestStateType>({
      Data: [],
      Filters: {
        Config: {},
        CurrentPage: 1,
        Name: '',
        Options: {},
        PageSize: 50,
        Search: '',
        SortColumn: '',
        SortDirection: '',
      },
      Key: 0,
      Total: 0,
    })
    const [patientDateRange, setPatientDateRange] = useState<DateRangeState>({
      dates: [null, null],
      numberOfDays: 0,
    })

    // state for appointments
    const [appointmentState, setAppointmentState] = useState<RequestStateType>({
      Data: [],
      Filters: {
        Config: {},
        CurrentPage: 1,
        Name: '',
        Options: {},
        PageSize: 50,
        SortColumn: 'AppointmentDate',
        SortDirection: 'desc',
      },
      Key: 0,
      Summary: {
        Appointments: 0,
        Eligible: 0,
        NeedsAttention: 0,
        PendingPatientResponse: 0,
      },
      Total: 0,
    })

    const [appointmentDateRange, setAppointmentDateRange] =
      useState<DateRangeState>({
        dates: [startOfDay(new Date()), endOfDay(new Date())],
        numberOfDays: 0,
      })

    // state for one-time history
    const [oneTimeRequestState, setOneTimeRequestState] =
      useState<OneTimeRequestStateType>({
        Data: [],
        Filters: {
          Config: {},
          CurrentPage: 1,
          Name: '',
          PageSize: 50,
        },
        Key: 0,
        OriginalData: [],
        Total: 0,
      })

    const isActionCalledFromTable$ = useObservable<boolean>(false)

    return (
      <EligibilityContext.Provider
        value={{
          activeTab,
          setActiveTab,
          appointmentDateRange,
          setAppointmentDateRange,
          appointmentState,
          setAppointmentState,
          loading,
          setLoading,
          isActionCalledFromTable$,
          oneTimeRequestState,
          setOneTimeRequestState,
          participatingCarriers,
          setParticipatingCarriers,
          patientDateRange,
          setPatientDateRange,
          patientState,
          setPatientState,
          providers,
          setProviders,
          requiresTableRefresh,
          setRequiresTableRefresh,
          setShowEligibilityResponseModal,
          setShowPatientUpdateFormModal,
          setVerificationResponse,
          showAppointmentsSummary$,
          showEligibilityResponseModal,
          showPatientUpdateFormModal,
          verificationResponse,
        }}
      >
        {children}
      </EligibilityContext.Provider>
    )
  },
)

export const useEligibilityContext = () => {
  const context = useContext(EligibilityContext)
  if (context === undefined)
    throw new Error('Context must be used within a Provider')

  return context
}

export default EligibilityContextProvider
