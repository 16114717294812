import { useCallback } from 'react'
import html2pdf from 'html2pdf.js'
import { LogError } from 'utils'
import { showMessage } from 'trellis:utilities/general'

// Docs for html2pdf: https://ekoopmans.github.io/html2pdf.js/#options
// Currently returns a function that generates a single page PDF with preset options

interface UseGeneratePDFOptions {
  htmlHeight: number
  htmlContent: string | null | undefined
  fileName?: string
}

const useGeneratePDF = () => {
  const generatePDF = useCallback(
    ({
      htmlHeight,
      htmlContent,
      fileName = 'document.pdf',
    }: UseGeneratePDFOptions) => {
      
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlContent || ''

      tempDiv.style.width = '210mm' // Width of default paper size
      tempDiv.style.margin = '0 auto'

      const contentWidth = 775
      const contentHeight = htmlHeight

      // Define the options
      const options = {
        margin: [10, 10, 10, 10],
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 1.5,
        },
        jsPDF: {
          unit: 'px',
          format: [contentWidth, contentHeight],
          orientation: 'portrait',
          hotfixes: ['px_scaling'],
        },
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      html2pdf()
        .from(tempDiv)
        .outputPdf('datauristring')
        .then((pdfDataUri: string) => {
          const pdfWindow = window.open('', '_blank')
          if (pdfWindow) {
            pdfWindow.document.write(
              `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
            )
          }
        })
        .catch((err) => {
          showMessage('There was an error generating the PDF', 'error')
          LogError('Error generating PDF', err)
        })
    },
    []
  )

  return { generatePDF }
}

export default useGeneratePDF
