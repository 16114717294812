import { PrinterOutlined } from '@ant-design/icons'
import { VyneButton } from '@vynedental/design-system'
import { Popover, Tooltip } from 'antd'
import React, { FC, useState } from 'react'

import { AttachmentPrint } from '../../../attachment/attachmentDetails/attachmentPrint'
import { useClaimDetailContext } from '../../context/claimDetailContext'

const PrintIcon: FC = () => {
  const [showingPrintPopover, setShowingPrintPopover] = useState(false)
  const {
    claim,
    printClaim,
    printHtml,
    printingClaim,
    printingEob,
    printingEra,
    printingClaimReceipt,
    attachment,
  } = useClaimDetailContext()

  const eob = claim?.EOB_HTML
  const era = claim?.ERA_HTML
  const claimReceipt = claim?.CLAIM_RECEIPT_HTML

  const showOtherPrintOptions: boolean =
    !!eob || !!era || attachment?.isSentAttachment === true

  const printContent: JSX.Element = (
    <div className='print-icon-popover'>
      <VyneButton
        dataTestId='print-claim-button'
        loading={printingClaim}
        onClick={() => printClaim()}
        type='primary'
      >
        Claim
      </VyneButton>
      {eob && (
        <VyneButton
          dataTestId='print-eob-button'
          loading={printingEob}
          onClick={() => printHtml(eob)}
        >
          EOB
        </VyneButton>
      )}
      {era && (
        <VyneButton
          dataTestId='print-era-button'
          loading={printingEra}
          onClick={() => printHtml(era)}
        >
          ERA
        </VyneButton>
      )}
      {claimReceipt && (
        <VyneButton
          dataTestId='print-claim-receipt-button'
          loading={printingClaimReceipt}
          onClick={() => printHtml(claimReceipt)}
        >
          Claim Receipt
        </VyneButton>
      )}
      {attachment?.isSentAttachment === true && (
        <VyneButton
          dataTestId='print-attachment-button'
          onClick={() => AttachmentPrint(attachment, claim)}
        >
          Attachment
        </VyneButton>
      )}
    </div>
  )

  return (
    <>
      {showOtherPrintOptions ? (
        <Popover
          content={printContent}
          title='Print'
          trigger='click'
          placement='bottom'
          open={showingPrintPopover}
          onOpenChange={(isVisible: boolean) =>
            setShowingPrintPopover(isVisible)
          }
        >
          <Tooltip
            title='Print'
            mouseEnterDelay={0.5}
          >
            <PrinterOutlined
              onClick={() => setShowingPrintPopover(true)}
              className='grid-control-icon'
            />
          </Tooltip>
        </Popover>
      ) : (
        <Tooltip
          title='Print'
          mouseEnterDelay={0.5}
        >
          <PrinterOutlined
            onClick={() => printClaim()}
            className='grid-control-icon'
          />
        </Tooltip>
      )}
    </>
  )
}

export default PrintIcon
